import { Component, OnInit } from '@angular/core';
import { AlertController, MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Observable, timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { filter } from 'rxjs/operators';
import { map, mergeMap } from 'rxjs/internal/operators';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET, RoutesRecognized } from '@angular/router';
import { TranslateProvider, AuthService, Events, OrdersDB, CommercesDB } from './services';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  public appPages: Observable<Array<any>>;
  public user: any;
  showMain1 = false;
  isLogged = false;
  email = '';
  photoURL = '';
  is_super = false;
  breadcrumbs;

  isLogout:boolean = false;

  pages = [
    { title: 'Home', url:'home', icon: 'home'},
    // { title: 'Admin zonas', url: 'admin-zone', icon: 'people'},
    { title: 'Afiliados', url:'affiliates', icon: 'assets/img/store-alt-solid.svg'},
    { title: 'Yets', url: 'yets', icon: 'assets/img/moto-2.svg' },
    { title: 'Clientes', url: 'clients', icon: 'person' },
    { title: 'Pagos', url: 'payments', icon: 'cash' },
    { title: 'Reporte de problemas', url: 'issues', icon: 'alert'}
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateProvider,
    private translateService: TranslateService,
    public events: Events,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private commercesDB: CommercesDB,
    private ordersDB: OrdersDB,
    private alert: AlertController,
  ) {
    this.initializeApp();
    events.subscribe('alert:global', (data) => {
      console.log('event alert:global' + data)
    });
   
  }

  ngOnInit() {
   
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }))
      .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
      .subscribe(route => {

        let snapshot = this.router.routerState.snapshot;
        this.breadcrumbs = [];
        let url = snapshot.url;
        let routeData = route.snapshot.data;

        let label = routeData['breadcrumb'];
        let params = snapshot.root.params;

        this.breadcrumbs.push({
          url: url,
          label: label,
          params: params
        });

      });
  }
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      setTimeout(() => {
        this.splashScreen.hide();
      }, 1000);
      // Set language of the app.
      this.translateService.setDefaultLang(environment.language);
      this.translateService.use(environment.language);
      this.translateService.getTranslation(environment.language).subscribe(translations => {
        this.translate.setTranslations(translations);
      });
    })
      .catch(() => {
        // Set language of the app.
        this.translateService.setDefaultLang(environment.language);
        this.translateService.use(environment.language);
        this.translateService.getTranslation(environment.language).subscribe(translations => {
          this.translate.setTranslations(translations);
        });
      });
  }

  SignOut() {
    this.auth.logout().then(() => {
      //this.isLogout = true;
    });
  }
  async askToSignOut() {
    const alert = await this.alert.create({
      header: 'Cerrar sesion?',
      message: '',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Si, salir del sistema',
          handler: () => {
            this.SignOut();
          }
        }
      ]
    });
    await alert.present();
  }
}
