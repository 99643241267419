import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ModalUserFilePageRoutingModule } from './modal-user-file-routing.module';

import { ModalUserFilePage } from './modal-user-file.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ModalUserFilePageRoutingModule
  ],
  declarations: [ModalUserFilePage]
})
export class ModalUserFilePageModule {}
