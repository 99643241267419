import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { OrderComponent } from './../components/order/order.component';
import { Main1Component } from './../components/main1/main1.component';
import { HeaderComponent } from './../components/header/header.component';
import { SideAccountComponent } from './../components/side-account/side-account.component';
import { SideAlertComponent } from './../components/side-alert/side-alert.component';
import { SideChatComponent } from './../components/side-chat/side-chat.component';
import { CommonModule } from '@angular/common';
@NgModule({
    declarations:[
        OrderComponent,
        Main1Component,
        HeaderComponent,
        SideAccountComponent,
        SideAlertComponent,
        SideChatComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
    ],
    exports:[
        OrderComponent,
        Main1Component,
        HeaderComponent,
        SideAccountComponent,
        SideAlertComponent,
        SideChatComponent,
    ],
})
export class ComponentsModule {}