import { Component, OnInit } from '@angular/core';
import { TranslateProvider, AuthService, Events, OrdersDB, CommercesDB } from './../../services';
import { Platform, NavController, MenuController, AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-side-account',
  templateUrl: './side-account.component.html',
  styleUrls: ['./side-account.component.scss'],
})
export class SideAccountComponent implements OnInit {
  email = '';
  photoURL = '';
  is_super = false;
  sub1: Subscription;
  constructor(
    private auth: AuthService,
    private menu: MenuController,
    public storage: Storage,
    public navCtrl: NavController,
    private alert: AlertController,
    public events: Events,
  ) {
    this.events.subscribe('main:account', (data) => {
      if (data['openAccount']) {
        this.openAccount();
      } else {
        this.closeAccount();
      }
    });
    this.load();
  }

  ngOnInit() { }

  load() {
    //console.log('sideAccount load')
    if (this.sub1) { this.sub1.unsubscribe(); }
    this.sub1 = this.auth.getUserAsync('sideAccount').subscribe(userLocal => {
      console.log(userLocal)
      this.email = userLocal.email;
      this.photoURL = userLocal.photoURL;
      this.is_super = userLocal.is_super;
    }),((e) => { console.log(e); });
  }
  accounOn() {
    this.menu.enable(true, 'menuAccount');
  }
  accounOff() {
    this.menu.enable(false, 'menuAccount');
  }
  openAccount() {
    this.accounOn();
    this.menu.open('menuAccount');
  }
  closeAccount() {
    this.menu.close('menuAccount');
  }
  goToPerfil() {
    this.closeAccount();
    this.navCtrl.navigateRoot('/profile');
  }
  SignOut() {
    this.auth.logout().then(() => {
    });
  }
  async askToSignOut() {
    const alert = await this.alert.create({
      header: 'Cerrar sesion?',
      message: '',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Si, salir del sistema',
          handler: () => {
            this.SignOut();
          }
        }
      ]
    });
    await alert.present();
  }
}
