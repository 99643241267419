import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Verification, DriverData, User  } from './../../models';
import { AlertController, ModalController, IonContent } from '@ionic/angular';
import { FirestoreService, AuthService, ProductsDB, CategoriesDB, StorageService } from './../../services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-user-file',
  templateUrl: './modal-user-file.page.html',
  styleUrls: ['./modal-user-file.page.scss'],
})
export class ModalUserFilePage implements OnInit {
  user = new User();
  @ViewChild(IonContent, { static: false }) content: IonContent;
  @Input() option: string;
  @Input() verification = new Verification();
  driverData = new DriverData();
  fileImgFront: string = "";
  fileImgBack: string = "";
  daySelected:String = "Lunes";
  sub1: Subscription;
  no_approved = false;
  comment = '';
  is_editing1 = false;
  constructor(
    private modal: ModalController,
    public firestore: FirestoreService,
    private alert: AlertController,

  ) { }

  ngOnInit() {
    console.log(this.verification)
    this.fileImgFront = "";
    this.fileImgBack = "";
    this.loadItems('');
  }
  loadItems(val1) {
    if(this.option == 'Edicion de datos'){
      this.loadPersonalData();
      this.is_editing1 = true;
    }else if(this.option == 'Documento unico de identidad'){
      this.fileImgFront = this.verification.dui_photo_front;
      this.fileImgBack = this.verification.dui_photo_back;
      this.is_editing1 = this.fileImgFront !="" ? true : false;
    }else if(this.option == 'Driver photo'){
      this.fileImgFront = this.verification.user_photo;
      this.is_editing1 = this.fileImgFront !="" ? true : false;
    }else if(this.option == 'Licencia de conducir'){
      this.fileImgFront = this.verification.license_photo_front;
      this.fileImgBack = this.verification.license_photo_back;
      this.is_editing1 = this.fileImgFront !="" ? true : false;
    }else if(this.option == 'Tarjeta de circulacion'){
      this.fileImgFront = this.verification.circulation_photo_front;
      this.fileImgBack = this.verification.circulation_photo_back;
      this.is_editing1 = this.fileImgFront !="" ? true : false;
    }else if(this.option == 'Solvencia policial'){
      this.fileImgFront = this.verification.pnc_photo_front;
      this.fileImgBack = this.verification.pnc_photo_back;
      this.is_editing1 = this.fileImgFront !="" ? true : false;
    }else if(this.option == 'Solvencia de antecedentes'){
      this.fileImgFront = this.verification.criminal_photo_front;
      this.fileImgBack = this.verification.criminal_photo_back;
      this.is_editing1 = this.fileImgFront !="" ? true : false;
    }else if(this.option == 'vehicle'){
      this.loadPersonalData();
      this.is_editing1 = true;
    }else if(this.option == 'payment'){
      this.loadPersonalData();
      this.is_editing1 = true;
    }else if(this.option == 'schedule'){
      this.loadPersonalData();
      this.is_editing1 = true;
    }
  }

  loadPersonalData() {
    if (this.sub1) { this.sub1.unsubscribe(); }
    this.sub1 = this.firestore.getOne('driver_data', this.verification.uid).subscribe((res0) => {
    if(res0 != undefined){
      this.driverData = res0;
      this.is_editing1 = true
      console.log(this.driverData);
      this.firestore.getOne('users',this.driverData.user_uid).subscribe(res2 => {
        this.user = res2;
      });
    }
    }),((e) => {
      console.log(e)
    });
  }

  closeModal() {
    this.modal.dismiss(false);
  }
  async askApprove() {
    const alert = await this.alert.create({
      header: 'Confirmar para aprobar?',
      message: '',
      buttons: [
        {
          text: 'cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => { console.log('Confirm Cancel: blah'); }
        },
        {
          text: 'Si! Aprobar',
          cssClass: 'primary',
          handler: () => { 
            this.saveApproved(); }
        },
      ]
    });
    await alert.present();
  }
  askNoApprove(){
    this.no_approved = true;
    this.scrollToLabel('idConfirmButtonDesapproved');
  }
  scrollToLabel(label) {
    var titleELe = document.getElementById(label);
    this.content.scrollToPoint(0, titleELe.offsetTop, 1000);
  }
  saveApproved(){
    if(this.option == 'Edicion de datos'){
      this.verification.data_checking = false;
      this.verification.data_complete = true;
      this.verification.data_approved = true;
      this.verification.data_comment = '';
      this.saveDriverVerification();

    }else if(this.option == 'Documento unico de identidad'){
      this.verification.dui_checking = false;
      this.verification.dui_complete = true;
      this.verification.dui_approved = true;
      this.verification.dui_comment = '';
      this.saveDriverVerification();
      
    }else if(this.option == 'Licencia de conducir'){
      this.verification.driver_license_checking = false;
      this.verification.driver_license_complete = true;
      this.verification.driver_license_approved = true;
      this.verification.driver_license_comment = '';
      this.saveDriverVerification();
      
    }else if(this.option == 'Tarjeta de circulacion'){
      this.verification.circulation_card_checking = false;
      this.verification.circulation_card_complete = true;
      this.verification.circulation_card_approved = true;
      this.verification.circulation_card_comment = '';
      this.saveDriverVerification();
      
    }else if(this.option == 'Solvencia policial'){
      this.verification.pnc_solvency_checking = false;
      this.verification.pnc_solvency_complete = true;
      this.verification.pnc_solvency_approved = true;
      this.verification.pnc_solvency_comment = '';
      this.saveDriverVerification();
      
    }else if(this.option == 'Solvencia de antecedentes'){
      this.verification.criminal_solvency_checking = false;
      this.verification.criminal_solvency_complete = true;
      this.verification.criminal_solvency_approved = true;
      this.verification.criminal_solvency_comment = '';
      this.saveDriverVerification();
      
    }else if(this.option == 'Driver photo'){
      this.verification.photo_checking = false;
      this.verification.photo_complete = true;
      this.verification.photo_approved = true;
      this.verification.photo_comment = '';
      this.saveDriverVerification();
    }else if(this.option == 'vehicle'){
      this.verification.vehicle_checking = false;
      this.verification.vehicle_complete = true;
      this.verification.vehicle_approved = true;
      this.verification.vehicle_comment = '';
      this.saveDriverVerification();
    }else if(this.option == 'payment'){
      this.verification.payment_checking = false;
      this.verification.payment_complete = true;
      this.verification.payment_approved = true;
      this.verification.payment_comment = '';
      this.saveDriverVerification();
    }else if(this.option == 'schedule'){
      this.verification.schedule_checking = false;
      this.verification.schedule_complete = true;
      this.verification.schedule_approved = true;
      this.verification.schedule_comment = '';
      this.saveDriverVerification();
    }
  }
  saveNoApproved(){
    if(this.comment==''){
      alert('Escribe una nota');
    }else{
      if(this.option == 'Edicion de datos'){
        this.verification.data_checking = true;
        this.verification.data_complete = true;
        this.verification.data_approved = false;
        this.verification.data_comment = this.comment;
        this.saveDriverVerification();

      }else if(this.option == 'Documento unico de identidad'){
        this.verification.dui_checking = true;
        this.verification.dui_complete = true;
        this.verification.dui_approved = false;
        this.verification.dui_comment = this.comment;
        this.saveDriverVerification();
        
      }else if(this.option == 'Licencia de conducir'){
        this.verification.driver_license_checking = true;
        this.verification.driver_license_complete = true;
        this.verification.driver_license_approved = false;
        this.verification.driver_license_comment = this.comment;
        this.saveDriverVerification();
        
      }else if(this.option == 'Tarjeta de circulacion'){
        this.verification.circulation_card_checking = true;
        this.verification.circulation_card_complete = true;
        this.verification.circulation_card_approved = false;
        this.verification.circulation_card_comment = this.comment;
        this.saveDriverVerification();
        
      }else if(this.option == 'Solvencia policial'){
        this.verification.pnc_solvency_checking = true;
        this.verification.pnc_solvency_complete = true;
        this.verification.pnc_solvency_approved = false;
        this.verification.pnc_solvency_comment = this.comment;
        this.saveDriverVerification();
        
      }else if(this.option == 'Solvencia de antecedentes'){
        this.verification.criminal_solvency_checking = true;
        this.verification.criminal_solvency_complete = true;
        this.verification.criminal_solvency_approved = false;
        this.verification.criminal_solvency_comment = this.comment;
        this.saveDriverVerification();
        
      }else if(this.option == 'Driver photo'){
        this.verification.photo_checking = true;
        this.verification.photo_complete = true;
        this.verification.photo_approved = false;
        this.verification.photo_comment = '';
        this.saveDriverVerification();
      }else if(this.option == 'vehicle'){
        this.verification.vehicle_checking = true;
        this.verification.vehicle_complete = true;
        this.verification.vehicle_approved = false;
        this.verification.vehicle_comment = '';
        this.saveDriverVerification();
      }else if(this.option == 'payment'){
        this.verification.payment_checking = true;
        this.verification.payment_complete = true;
        this.verification.payment_approved = false;
        this.verification.payment_comment = '';
        this.saveDriverVerification();
      }else if(this.option == 'schedule'){
        this.verification.schedule_checking = true;
        this.verification.schedule_complete = true;
        this.verification.schedule_approved = false;
        this.verification.schedule_comment = '';
        this.saveDriverVerification();
      }
    }
  }
  saveDriverVerification(){
    this.firestore.save('driver_verification', this.verification).then((res0) => {
      this.comment = '';
      this.no_approved = false;
      this.closeModal();
      }),((e) => {
        console.log(e)
      });
  }
}