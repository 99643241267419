import { Component, OnInit } from '@angular/core';
import { TranslateProvider, AuthService, Events, OrdersDB, CommercesDB } from './../../services';
import { Platform, NavController, MenuController } from '@ionic/angular';

@Component({
  selector: 'app-side-chat',
  templateUrl: './side-chat.component.html',
  styleUrls: ['./side-chat.component.scss'],
})
export class SideChatComponent implements OnInit {
  email = '';
  photoURL = '';
  is_super = false;
  constructor(
    private auth: AuthService,
    private menu: MenuController,
    public navCtrl: NavController,
    public events: Events,
  ) {
    this.events.subscribe('main:chat', (data) => {
      console.log('chat '+ data)
      if (data['ChatOn']) { this.chat(); }
      if (data['ChatOff']) { this.ChatOff(); }
      if (data['chat']) { this.chat(); }
      if (data['closeChat']) { this.closeChat(); }
    });
  }

  ngOnInit() { }
  ChatOn() {
    this.menu.enable(true, 'menuChat');
  }
  ChatOff() {
    this.menu.enable(false, 'menuChat');
  }
  chat() {
    this.menu.open('menuChat');
  }
  closeChat() {
    this.menu.close('menuChat');
  }
}
