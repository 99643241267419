import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { FirestoreService } from 'src/app/services';

@Component({
  selector: 'app-issue-detail',
  templateUrl: './issue-detail.page.html',
  styleUrls: ['./issue-detail.page.scss'],
})
export class IssueDetailPage implements OnInit {
  @Input()issue = {
    message: '',
    resolved: false,
    rol: '',
    time: 1630968810973,
    timestamp: Timestamp,
    uid: '',
    user_email: '',
    user_uid: '',
  };

  constructor(
    private modalController: ModalController,
    private firestore: FirestoreService,
    private alertController: AlertController,
  ) { }

  ngOnInit() {
    console.log(this.issue.message);
  }

  closeModal(){
    this.modalController.dismiss();
  }

  changeIssueStatus(){
    this.issue.resolved = true;
    this.firestore.update('complaint',this.issue.uid,{...this.issue}).then(() => {
      this.showMessage("Se a maracado como resuelto","Se acaba de marcar este problema como resuelto");
    });
  }

  timeConverter(UNIX_timestamp){
    var year = UNIX_timestamp.getFullYear();
    var a = new Date(UNIX_timestamp);
    var months = ['ENE','FEB','MAR','ABR','MAY','JUN','JUL','AGO','SEP','OCT','NOV','DIC'];
    
    console.log(a.getMonth());
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + '/' + month + '/' + year + ' ' + hour + ':' + min;
    return time;
  }

  async showMessage(header,message){
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: ['OK']
    });

    return await alert.present();
  }
}
