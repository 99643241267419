import { NgModule } from '@angular/core';
import { PreloadAllModules, Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guard/auth.guard';

//import { PreloaderService } from './services'

const routes: Routes = [
  {
    path: '', redirectTo: 'home', pathMatch: 'full'
  },
  {
    path: 'inicio',
    loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard], data: { preload: true },
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersPageModule),
    canActivate: [AuthGuard], data: { preload: true }
  },
  {
    path: 'categories/:val1',
    loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesPageModule),
    canActivate: [AuthGuard], data: { preload: true }
  },
  {
    path: 'products/:val1',
    loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsPageModule),
    canActivate: [AuthGuard], data: { preload: true }
  },
  {
    path: 'user-detail/:val1',
    loadChildren: () => import('./pages/user-detail/user-detail.module').then(m => m.UserDetailPageModule),
    canActivate: [AuthGuard], data: { preload: true }
  },
  {
    path: 'user-create/:val1/:val2',
    loadChildren: () => import('./pages/user-create/user-create.module').then(m => m.UserCreatePageModule),
    canActivate: [AuthGuard], data: { preload: true }
  },
  {
    path: 'locations',
    loadChildren: () => import('./pages/locations/locations.module').then(m => m.LocationsPageModule),
    canActivate: [AuthGuard], data: { preload: true }
  },
  {
    path: 'commerces',
    loadChildren: () => import('./pages/commerces/commerces.module').then(m => m.CommercesPageModule),
    canActivate: [AuthGuard], data: { preload: true }
  },
  {
    path: 'local/:val1',
    loadChildren: () => import('./pages/local/local.module').then(m => m.LocalPageModule),
    canActivate: [AuthGuard], data: { preload: true }
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule),
    canActivate: [AuthGuard], data: { preload: true }
  },
  {
    path: 'stores',
    loadChildren: () => import('./pages/stores/stores.module').then(m => m.StoresPageModule),
    canActivate: [AuthGuard], data: { preload: true }
  },
  {
    path: 'store-show/:val1',
    loadChildren: () => import('./pages/store-show/store-show.module').then(m => m.StoreShowPageModule),
    canActivate: [AuthGuard], data: { preload: true }
  },
  {
    path: 'order-show/:val1',
    loadChildren: () => import('./pages/order-show/order-show.module').then(m => m.OrderShowPageModule),
    canActivate: [AuthGuard], data: { preload: true }
  },
  {
    path: 'payments',
    loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsPageModule),
    canActivate: [AuthGuard], data: { preload: true }
  },
  {
    path: 'category-edit/:val1',
    loadChildren: () => import('./pages/category-edit/category-edit.module').then(m => m.CategoryEditPageModule),
    canActivate: [AuthGuard], data: { preload: true }
  },
  {
    path: 'category-new/:val1',
    loadChildren: () => import('./pages/category-new/category-new.module').then(m => m.CategoryNewPageModule),
    canActivate: [AuthGuard], data: { preload: true }
  },
  {
    path: 'product-edit/:val1',
    loadChildren: () => import('./pages/product-edit/product-edit.module').then(m => m.ProductEditPageModule),
    canActivate: [AuthGuard], data: { preload: true }
  },
  {
    path: 'product-new/:val1',
    loadChildren: () => import('./pages/product-new/product-new.module').then(m => m.ProductNewPageModule),
    canActivate: [AuthGuard], data: { preload: true }
  },
  {
    path: 'store-new',
    loadChildren: () => import('./pages/store-new/store-new.module').then(m => m.StoreNewPageModule),
    canActivate: [AuthGuard], data: { preload: true }
  },
  {
    path: 'verification',
    loadChildren: () => import('./pages/verification/verification.module').then( m => m.VerificationPageModule),
    canActivate: [AuthGuard], data: { preload: true }
  },
  {
    path: 'affiliates',
    loadChildren: () => import('./pages/affiliates/affiliates.module').then( m => m.AffiliatesPageModule)
  },
  {
    path: 'affiliated-show/:val1',
    loadChildren: () => import('./pages/affiliated-show/affiliated-show.module').then( m => m.AffiliatedShowPageModule)
  },
  {
    path: 'yets',
    loadChildren: () => import('./pages/yets/yets.module').then( m => m.YetsPageModule)
  },
  {
    path: 'yet-show/:val1',
    loadChildren: () => import('./pages/yet-show/yet-show.module').then( m => m.YetShowPageModule)
  },
  {
    path: 'clients',
    loadChildren: () => import('./pages/clients/clients.module').then( m => m.ClientsPageModule)
  },
  {
    path: 'client-show/:val1',
    loadChildren: () => import('./pages/client-show/client-show.module').then( m => m.ClientShowPageModule)
  },
  {
    path: 'local-show/:val1',
    loadChildren: () => import('./pages/local-show/local-show.module').then( m => m.LocalShowPageModule)
  },
  {
    path: 'issues',
    loadChildren: () => import('./pages/issues/issues.module').then( m => m.IssuesPageModule)
  },
  {
    path: 'order-cancel',
    loadChildren: () => import('./pages/order-cancel/order-cancel.module').then( m => m.OrderCancelPageModule)
  },
  {
    path: 'admin-zone',
    loadChildren: () => import('./pages/admin-zone/admin-zone.module').then( m => m.AdminZonePageModule)
  },
  {
    path: 'add-admin-zona',
    loadChildren: () => import('./pages/add-admin-zona/add-admin-zona.module').then( m => m.AddAdminZonaPageModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
