import { Injectable } from '@angular/core';

import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection
} from '@angular/fire/firestore';
import * as firebase from 'firebase';

//import { GeoCollectionReference, GeoFirestore } from 'geofirestore';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
// import { GeoFirestore } from 'geofirestore';

import { User, Event, GeoMap, Song, Order } from '../../models';

@Injectable({
  providedIn: 'root'
})

export class FirestoreService {
  fsRef: any;
  
  firestore = firebase.firestore();

  //geofirestore: GeoFirestore =  new GeoFirestore(this.firestore);

  //geocollectionCommerces: GeoCollectionReference = this.geofirestore.collection('commerces_locations');

  constructor(
    private afs: AngularFirestore
  ) {

    /// Reference database location for GeoFirestore
    this.fsRef = this.afs.collection('geofirestore');
    // this.geoFirestore = new GeoFirestore(this.fsRef);
  }

  // Get an object from Firestore by its path. For eg: firestore.get('users/' + userId) to get a user object.
  public get(path: string): Promise<AngularFirestoreDocument<{}>> {
    return new Promise(resolve => {
      resolve(this.afs.doc(path));
    });
  }

  // Check if the object exists on Firestore. Returns a boolean promise with true/false.
  public exists(path: string): Promise<boolean> {
    return new Promise(resolve => {
      this.afs.doc(path).valueChanges().pipe(take(1)).subscribe(res => {
        if (res) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  // Get all users on Firestore ordered by their firstNames.
  public getUsers(): AngularFirestoreCollection<User> {
    return this.afs.collection('users', ref => ref.orderBy('firstName'));
  }

  // Get userData of a user given the username. Return the userData promise.
  public getUserByUsername(username: string): Promise<User> {
    return new Promise(resolve => {
      this.afs.collection('users', ref => ref.where('username', '==', username)).valueChanges().pipe(take(1)).subscribe((res: User[]) => {
        if (res.length > 0) {
          resolve(res[0]);
        }
      });
    });
  }

  public getUserByUID(uid: string): Promise<User> {
    return new Promise(resolve => {
      this.afs.collection('users', ref => ref.where('userId', '==', uid)).valueChanges().pipe(take(1)).subscribe((res: User[]) => {
        if (res.length > 0) {
          resolve(res[0]);
        }
      });
    });
  }

  // Get userData of a user given the pushToken. Return the userData promise.
  public getUserByPushToken(token: string): Promise<User> {
    return new Promise(resolve => {
      this.afs.collection('users', ref => ref.where('pushToken', '==', token)).valueChanges().pipe(take(1)).subscribe((res: User[]) => {
        if (res.length > 0) {
          resolve(res[0]);
        }
      });
    });
  }

  // Set the pushToken of the user given the userId.
  public setPushToken(userId: string, token: string): void {
    this.getUserByPushToken(token).then((user: User) => {
      if (user) {
        this.removePushToken(user.uid);
      }
      this.get('users/' + userId).then(ref => {
        ref.update({
          pushToken: token
        });
      }).catch(() => { });
    }).catch(() => { });
  }

  // Remove the pushToken of the user given the userId.
  public removePushToken(userId: string): void {
    this.get('users/' + userId).then(ref => {
      ref.update({
        pushToken: ''
      });
    }).catch(() => { });
  }

  /* GeoFirestore */
  /////////////////

  /// Adds GeoFire data to database
  setLocation(l: Array<number>, u: string, t: number) {
    const g = this.afs.createId();

    this.afs.doc(`geofirestore/${g}`).set({g, l, u, t});
    // this.geoFirestore.set(key, coords)
    //   .then(_ => console.log('location updated'))
    //   .catch(err => console.log(err));
  }

  // Get all Locations registered on Firestore by setLocation().
  public getAllLocations(): AngularFirestoreCollection<GeoMap> {
    return this.fsRef;
  }

  /* CRUD */
  /////////////////

  // Song //
  createSong(
    albumName: string,
    artistName: string,
    songDescription: string,
    songName: string,
    userId: string
  ): Promise<void> {
    const id = this.afs.createId();

    return this.afs.doc(`songList/${id}`).set({
      id,
      albumName,
      artistName,
      songDescription,
      songName,
      userId
    });
  }

  getSongList(userId: string): AngularFirestoreCollection<Song> {
    return this.afs.collection('songList', ref => ref.where('userId', '==', userId));
  }

  deleteSong(songId: string): Promise<void> {
    return this.afs.doc(`songList/${songId}`).delete();
  }

  updateSong(songId: string, albumName: string, artistName: string, songDescription: string, songName: string): Promise<void> {
    return this.afs.doc(`songList/${songId}`).update({
      albumName,
      artistName,
      songDescription,
      songName
    });
  }
  ////

  // Event //
  createEvent(
    eventName: string,
    eventDescription: string,
    eventLocation: string,
    eventDate: string,
    userId: string
  ): Promise<void> {
    const id = this.afs.createId();

    return this.afs.doc(`eventList/${id}`).set({
      id,
      eventName,
      eventDescription,
      eventLocation,
      eventDate,
      userId
    });
  }

  getEventList(userId: string): AngularFirestoreCollection<Event> {
    return this.afs.collection('eventList', ref => ref.where('userId', '==', userId));
  }

  deleteEvent(eventId: string): Promise<void> {
    return this.afs.doc(`eventList/${eventId}`).delete();
  }

  updateEvent(
    eventId: string,
    eventName: string,
    eventDescription: string,
    eventLocation: string,
    eventDate: number
    ): Promise<void> {
    return this.afs.doc(`eventList/${eventId}`).update({
      eventName,
      eventDescription,
      eventLocation,
      eventDate
    });
  }
  ////
  //----------------------------------------------------------
  public getOne<T>(collection: string, uid: string): Observable<any> 
  {
    return this.afs.doc<T>(`${collection}/${uid}`).valueChanges();
  }
  public getAll<T>(collection: string): Observable<any> 
  {
    return this.afs.collection<T>(collection).valueChanges();
  }
  public getAllByDate<T>(collection: string): Observable<any> 
  {
    return this.afs.collection<T>(collection, ref => ref.orderBy('timestamp','desc')).valueChanges();
  }
  public getWhere<T>(collection: string, key: string, value: any): Observable<any> 
  {
    return this.afs.collection<T>(collection, ref => ref.where(key , '==', value)).valueChanges();
  }
  public get2Where<T>(collection: string, key: string, value: string, key2: string, value2: string): Observable<any> 
  {
    return this.afs
    .collection<T>(collection, ref => ref
    .where(key, '==', value)
    .where(key2, '>=', value2)
    .where(key2, '<', value2)
    //.orderBy(key2)
    //.startAt(value2)
    //.endAt(value2+'\uf8ff')
    )
    .valueChanges();
  }
  public getWhere2<T>(collection, key1, value1, key2, value2): Observable<any> 
  {
    return this.afs
    .collection<T>(collection, ref => ref
    .where(key1, '==', value1)
    .where(key2, '==', value2)
    //.orderBy(key2)
    //.startAt(value2)
    //.endAt(value2+'\uf8ff')
    )
    .valueChanges();
  }
  public getWhere3<T>(collection, key1, value1, key2, value2, key3, value3): Observable<any> {
    return this.afs
      .collection<T>(collection, ref => ref
        .where(key1, '==', value1)
        .where(key2, '==', value2)
        .orderBy('created_at')
        .startAt(key3)
        .endAt(value3)
      )
      .valueChanges();
  }

  public getWhere2_3<T>(collection, key1, value1, key3, value3): Observable<any> {
    return this.afs
      .collection<T>(collection, ref => ref
        .where(key1, '==', value1)
        .orderBy('created_at')
        .startAt(key3)
        .endAt(value3)
      )
      .valueChanges();
  }

  public getByDate<T>(collection, start, end): Observable<any> {
    return this.afs
      .collection<T>(collection, ref => ref
        .orderBy('create_at')
        .startAt(start)
        .endAt(end)
      )
      .valueChanges();
  }

  public getWhereWithRange<T>(collection, key1, value1, key2, value2, value3): Observable<any> {
    return this.afs
      .collection<T>(collection, ref => ref
        .where(key1, '==', value1)
        .orderBy(key2)
        .startAt(value2)
        .endAt(value3)
      )
      .valueChanges();
  }
  public getWhereIn<T>(collection: string,key: string, arrayValues: any): Observable<any> {
    console.log(collection)
    console.log(key)
    console.log(arrayValues)
    //userCollection.where('uid', 'in', ["1231","222","2131"])
    //return this.afs.collection<T>(collection, arrayValues).valueChanges();
    return this.afs.collection<T>(collection, ref => ref.where(key, 'in', arrayValues)).valueChanges();
  }
  public getPagination<T>(collection): Observable<any> 
  {
    return this.afs.collection<T>(collection, ref => ref.orderBy('created_at').limit(100)).valueChanges();
  }
  public getWherePagination<T>(collection: string, key: string, value: string): Observable<any> 
  {
    return this.afs
    .collection<T>(collection, ref => ref
    .where(key, '>=', value)
    .where(key, '<=', value + '\uf8ff')
    .limit(10))
    .valueChanges();
  }
  public save<T>(collection: string, document): Promise<any> 
  {
    document.uid = document.uid!=null&&document.uid!=''&&document.uid!=undefined ? document.uid : this.afs.createId();
    return this.afs.doc<T>(`${collection}/${document.uid}`).set(document);
  }
  public update<T>(collection: string, uid: string, document): Promise<any> 
  {
    return this.afs.doc<T>(`${collection}/${uid}`).update(document);
  }
  public delete<T>(collection: string, uid: string): Promise<any> 
  {
    return this.afs.doc<T>(`${collection}/${uid}`).delete();
  }

  public updateArrayMessages<T>(collectionUid: string, document): Promise<any> {
    return this.afs.doc(`${collectionUid}`).update({
      messages: firebase.firestore.FieldValue.arrayUnion(document)
    });
  }

  public search(collectionName, objectSearch) {
    console.log(objectSearch);

    /*
      After applying these query you may face this error:
      "ERROR FirebaseError: The query requires an index. You can create it here: URL"
      You will get above error with an URL - Click over that URL - Login in Firebase
      and this will prompt to Create an Index which is required in Firebase 
      to apply queries to Database Collection.
    */
    return this.afs.collection(collectionName, ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        //for (const prop in objectSearch) { query = query.where(`${prop}`, '==', `${objectSearch[prop]}`); }
        if (objectSearch.phone) {
          query = query.where('phone', '==', objectSearch.phone);
        }
        if (objectSearch.salary) {
          query = query.where('salary', '>=', objectSearch.salary);
        }
        if (objectSearch.designation) {
          query = query.where('designation', '==', objectSearch.designation);
        }
        if (objectSearch.sucursal_uid) {
          query = query.where('sucursal_uid', '==', objectSearch.sucursal_uid);
        }
        if (objectSearch.commerce_uid&&objectSearch.commerce_uid!=''&&objectSearch.commerce_uid!=undefined) {
          query = query.where('commerce_uid', '==', objectSearch.commerce_uid);
        }
        if (objectSearch.manager_user_uid&&objectSearch.manager_user_uid!=''&&objectSearch.manager_user_uid!=undefined) {
          query = query.where('manager_user_uid', '==', objectSearch.manager_user_uid);
        }
        if (objectSearch.locations_uid&&objectSearch.locations_uid!=''&&objectSearch.locations_uid!=undefined) {
          query = query.where('locations_uid', '==', objectSearch.locations_uid);
        }
        
        if (objectSearch.joinDate) {
          objectSearch.joinDateTimestamp = new Date(objectSearch.joinDate);
          query = query.where('joinDateTimestamp', '==', objectSearch.joinDateTimestamp);
        }
        // query = query.orderBy('joinDateTimestamp', 'desc');
        // query = query.orderBy('designation').limit(2);
        /* IMPORTANT: Reason I put this query at last because
         * We can not call Query.startAt() or Query.startAfter()
         * before calling Query.orderBy().
        */
        if (objectSearch.name) {
          query = query.where('name', '>=', objectSearch.name)
          query = query.where('name', '<=', objectSearch.name + '\uf8ff')
          
          // name starts with `An` then apply startAt('An')
          
          /* similar query `endAt`, `startAfter` and `endBefore`
              can be applied like this:
          */
          // query = query.endAt('An');
          // query = query.startAfter('An');
          // query = query.endBefore('An');
        }
        query = query.limit(objectSearch.limit)
        query = query.orderBy(objectSearch.orderByName, objectSearch.orderByDir);
        return query;
    }).snapshotChanges();
  }
  createUID(){
    return this.afs.createId();
  }

  /* public getCommercesWhere(uid) {
    return this.geocollectionCommerces.where('manager_user_uid', '==', uid).get();
  } */

}
