import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { AngularFireStorage } from "@angular/fire/storage";
import { AngularFireFunctions } from '@angular/fire/functions';
import { Commerce, User } from './../../models';
import { FirestoreService, AuthService, CommercesDB, StorageService, NotificationService } from './../../services';
import { map, finalize } from "rxjs/operators";
import { Observable } from "rxjs";
import { AlertController } from '@ionic/angular';
import { Location } from "@angular/common";
import { Map, tileLayer, marker, icon, circle } from 'leaflet';
import * as L from 'leaflet';
import 'leaflet-routing-machine';
@Component({
  selector: 'app-local-show',
  templateUrl: './local-show.page.html',
  styleUrls: ['./local-show.page.scss'],
})
export class LocalShowPage implements OnInit {
  tableData = [];
  model = new Commerce();
  modelUser = new User();
  map: Map;
  localMarker: any;
  isGift:boolean;
  defaultPin = {
    'lat': 13.6967454,
    'lng': -89.2088985,
  }
  ngCategory:string;
  categories = [
    {value: "Alimentos", name: "Alimentos"},
    {value: "Bebidas", name: "Bebidas"},
    {value: "Ropa", name: "Ropa"},
    {value: "Zapaterias", name: "Zapaterias"},
    {value: "Supermercado", name: "Supermercado"},
    {value: "Conveniencias", name: "Conveniencias"},
    {value: "Farmacias", name: "Farmacias"},
    {value: "Ferreterias", name: "Ferreterias"},
    {value: "Repuestos", name: "Repuestos"},
    {value: "Salud", name: "Salud"},
    {value: "Tecnologia", name: "Tecnologia"},
    {value: "Mascotas", name: "Mascotas"},
  ]
  commerceStatus:string;

  constructor(
    private storage: AngularFireStorage,
    private router: Router,
    private firestore: FirestoreService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private commercesDB: CommercesDB,
    private storageS: StorageService,
    private alert: AlertController,
    private notification: NotificationService,
    private functions: AngularFireFunctions,
  ) {
    let paramUID = this.activatedRoute.snapshot.paramMap.get('val1');
    if (paramUID) {
      this.loadItems(paramUID);
    } else {
      this.location.back();
    }
  }
  ngOnInit() {
  }

  goToLocal(val1) {
    //this.router.navigate(['/local/'+val1]);
  }
  loadItems(val1) {
    this.firestore.getOne('commerces', val1).subscribe(res1 => {
      this.model = res1;
      this.isGift = this.model.enableGift;
      console.log("============================");
      console.log(this.model)
      this.model.created_at = new Date();
      console.log("new date");
      console.log(this.model.created_at);
      if (this.model.latitude != '' && this.model.longitude != '') {
        this.loadMap(this.model.latitude, this.model.longitude);
      } else {
        this.loadMap(this.defaultPin.lat, this.defaultPin.lng);
      }
      if (this.model.manager_user_uid != '') {
        this.loadAffiliated();
      }
    });
  }
  askUpdateStatus() {
    //let option = (<HTMLInputElement>document.getElementById('select-filter')).value;
    console.log(this.commerceStatus);
    if(this.commerceStatus == "active"){
      this.updateStatus(true);
    }
    else if(this.commerceStatus == "inactive"){
      this.updateStatus(false);
    }else{
      console.log("do nothing");
    }
  }
  
  loadAffiliated() {
    this.firestore.getOne('users', this.model.manager_user_uid).subscribe(res1 => {
      this.modelUser = res1;
      console.log(this.model)
    });
  }
  backPage() {
    this.router.navigate(['/affiliated-show/' + this.model.manager_user_uid]);
  }
  updateStatus(val1) {
    if (this.model.uid != '' && this.model.uid != null && this.model.uid != undefined) {
      this.model.is_enabled = val1;
      let local_status = val1 ? 'Habilitado' : 'Deshabilitado';
      this.firestore.update('commerces', this.model.uid, { ...this.model }).then((data) => {
        this.notification.getPushToken(this.model.uid).then((res0) => {
          let objNotify = { local_status, user_email: this.modelUser.email, local_uid: this.model.uid, local_name: this.model.name, pushToken: res0['pushToken'], };
          this.notification.notify('notifyStatusLocalUpdated', objNotify);          
          alert('Se actualizo la informacion');
        }).catch((e) => {
          console.log(e);
        });
      }).catch(function (error) {
        console.log(error);
        alert('No se puede guardar.. Intentalo mas tarde');
      });
    } else {
      alert('No se puede guardar.. Intentalo mas tarde');
    }
  }
  loadMap(lat_delivery_end, lng_delivery_end) {

    window.dispatchEvent(new Event('resize'));

    document.getElementById('containnerMap').innerHTML = `<div id="yelouMap" class="yelouMap" style="width:100%;height: 100%;">cargando mapa...</div>`;

    this.map = new Map('yelouMap').setView([lat_delivery_end, lng_delivery_end], 13);

    window.dispatchEvent(new Event('resize'));

    tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' }).addTo(this.map);

    this.addLocalMarker(lat_delivery_end, lng_delivery_end);

  }
  addLocalMarker(lat, lon) {
    const customMarkerIcon = icon({ iconUrl: 'assets/img/pin-local.png', iconSize: [64, 128], popupAnchor: [0, -20] });
    this.localMarker = marker([lat, lon], { icon: customMarkerIcon }).addTo(this.map).openPopup();
  }
  onChangeCategoryCommerce(e) {
    if (this.model.category_commerce != e.detail.value && e.detail.value != '') {
      this.model.category_commerce = e.detail.value;
      this.firestore.update('commerces', this.model.uid, { ...this.model }).then((data) => {
        alert('Informacion actualizada');
      }).catch(function (error) {
        console.log(error);
        alert('No se puede guardar.. Intentalo mas tarde(1)');
      });
    } else {
      alert('No se puede guardar.. Intentalo mas tarde(2)');
    }
  }

  timeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['ENE','FEB','MAR','ABR','MAY','JUN','JUL','AGO','SEP','OCT','NOV','DIC'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + '/' + month + '/' + year + ' ' + hour + ':' + min;
    return time;
  }

  enableLocaGift(){
    this.model.enableGift = this.isGift;
    this.firestore.update('commerces',this.model.uid,{...this.model}).then(() => {
      alert('Informacion actualizada');
    }).catch(error => {console.log(error)});
  }

  changeCategory(){
    this.model.category_commerce = this.ngCategory;
    this.firestore.update('commerces',this.model.uid,{...this.model}).then(() => {
      alert('Informacion actualizada');
    }).catch(error => {console.log(error)});
  }
}