import { Injectable } from '@angular/core';
import { Category } from '../../models';
import { FirestoreService } from './firestore.service';
import { Events } from './../events/events.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoriesDB {
  model = new BehaviorSubject(new Category);
  arrayModel = new BehaviorSubject([]);
  arrayModelAll = new BehaviorSubject([]);
  arrayAlerts = new BehaviorSubject([]);
  collectionName = 'categories';
  collectionField = 'created_at';
  orderByDir: any = 'desc';
  collectionLimit = 500;
  constructor(
    private fs: FirestoreService,
    private events: Events,
    ) { }
    getOne(uid) {
      let findByUID = this.arrayModelAll.value.filter(item => item['uid'] == uid);
      if (findByUID.length > 0) {
        this.setOne(findByUID[0]).then((res1) => {
        }), e => { console.log(e); };
      } else {
        this.fs.getOne(this.collectionName, uid).subscribe(res1 => {
          this.setOne(res1).then((res2) => {
          }), e => { console.log(e); };
        }), e => { console.log(e); };
      }
      return this.model.asObservable();
    }
    setOne(val1) {
      return new Promise((resolve, reject) => {
        this.model.next(val1);
        resolve(true);
      });
    }
    getWhere(key1, val1) {
      if ((this.arrayModel.value.length == 0)) {
        this.fs.getWhere(this.collectionName, key1, val1).subscribe(res1 => {
          this.shortByCreatedAt(res1).then((res2) => {
            this.setWhere(res1).then((res3) => {
              return this.arrayModel.asObservable();
            }), e => { console.log(e); };
          }), e => { console.log(e); };
        }), e => { console.log(e); };
      } else {
        return this.arrayModel.asObservable();
      }
    }
    setWhere(val1){
      return new Promise((resolve, reject) => {
        this.arrayModel.next(val1);
        resolve(true);
      });
    }
    getAll(uid) {
        this.fs.getWhere(this.collectionName, 'commerce_uid', uid).subscribe(res1 => {
          this.shortByCreatedAt(res1).then((res2) => {
            this.setAll(res1).then((res3) => {
            }), e => { console.log(e); };
          }), e => { console.log(e); };
        }), e => { console.log(e); };
        return this.arrayModelAll.asObservable();
    }
    setAll(val1) {
      return new Promise((resolve, reject) => {
        this.arrayModelAll.next(val1);
        resolve(true);
      });
    }
    shortByCreatedAt(val1) {
      return new Promise((resolve, reject) => {
        val1.sort(function (a, b) {
          if (a.created_at > b.created_at) { return -1; }
          if (a.created_at < b.created_at) { return 1; }
          return 0;
        });
        resolve(val1);
      });
    }
  /*public sync(commerce_uid):Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.arrayModel.length>0) {
        console.log(`sync ${this.collectionName} first`);
        resolve(this.arrayModel)
      } else {
        console.log(`sync ${this.collectionName} cache`);
        this.fs.getWhere(this.collectionName,'commerce_uid',commerce_uid).subscribe(res1 =>{
          this.events.publish('categories:load', { 'load': true });
          this.arrayModel = res1;
          resolve(this.arrayModel);
        }), e => { console.log(e); reject(e); };;
      }
    });
  }
  public getOneByUID(uid): Promise<any> {
    return new Promise((resolve, reject) => {
      if (uid) {
        this.model = new Category();
        let findByUID = this.arrayModel.filter(item => item['uid'] == uid);
        if (findByUID.length > 0) {
          this.model = findByUID[0];
          resolve(this.model);
        } else {
          this.fs.getOne(this.collectionName, uid).subscribe(res1 => {
            this.model = res1;
            resolve(this.model);
          }), e => { console.log(e); reject(e); };
        }
      } else {
        resolve(this.model);
      }
    });
  }*/
}
