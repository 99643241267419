import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Platform, NavController, MenuController, Events } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { CommonModule } from '@angular/common';

import { Storage } from '@ionic/storage';
import { Observable, timer, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateProvider, AuthService, NotificationService} from './../../services';
@Component({
  selector: 'app-main1',
  templateUrl: './main1.component.html',
  styleUrls: ['./main1.component.scss'],
})
export class Main1Component implements OnInit {
  user;
  isLogged = false;
  photoURL = "";
  email = "";
  sub1: Subscription;

  constructor(
    private auth:AuthService,
    private menuCtrl:MenuController,
  ) { }

  ngOnInit() {
    if (this.sub1) { this.sub1.unsubscribe(); }
    this.auth.getUser('main1').then(userLocal => {
      this.isLogged = true;
      this.email = userLocal.email;
      this.photoURL = userLocal.photoURL;
      this.menuCtrl.enable(true);
    }),((e)=>{console.log(e);this.menuCtrl.enable(false);});
  }
}
