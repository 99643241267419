import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy,RouterModule } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Camera } from '@ionic-native/camera/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { FCM } from '@ionic-native/fcm/ngx';
import { File } from '@ionic-native/file/ngx';
import { Network } from '@ionic-native/network/ngx';

import { AgmCoreModule } from '@agm/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicStorageModule } from '@ionic/storage';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireFunctionsModule, FUNCTIONS_REGION, } from '@angular/fire/functions';

import { IonImgLazyLoadModule } from './directives/ionimg-lazy-load.module';
import { ComponentsModule } from './components/components.module';
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgxPrintModule} from 'ngx-print';

//import { ButtonCart1Component } from './components/button-cart1/button-cart1.component'
//import { ButtonCart1Component } from '../app/components/button-cart1/button-cart1.component'
//MODALS
import { ModalUserFilePageModule } from './modals/modal-user-file/modal-user-file.module';
import { IssueDetailPageModule } from './modals/issue-detail/issue-detail.module';
import { LocalShowPageModule } from './pages/local-show/local-show.module';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: 
  [
    AppComponent,
  ],
  entryComponents: 
  [
  ],
  imports: 
  [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    AngularFireFunctionsModule,
    ComponentsModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    NgxPrintModule,
    IonicModule.forRoot({
      //iconMode: 'md',
      //mode: 'md',
      //iconMode: 'ios',
      mode: 'ios',
      //preloadModules: true,
      //scrollPadding: false,
      //scrollAssist: true,
      //autoFocusAssist: false,
      menuType: 'overlay'
      }),
    AppRoutingModule,
    HttpClientModule,
    IonImgLazyLoadModule,
    IonicStorageModule.forRoot({
      name: '__yellow503',
      driverOrder: ['indexeddb', 'sqlite', 'websql']
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsKey
    }),
    ServiceWorkerModule.register('combined-worker.js', { enabled: environment.production }),
  ],
  exports: 
  [
    ModalUserFilePageModule,
    IssueDetailPageModule,
    LocalShowPageModule,
  ],
  providers: 
  [
    Camera,
    Device,
    Geolocation,
    StatusBar,
    SplashScreen,
    Keyboard,
    Facebook,
    GooglePlus,
    File,
    FCM,
    Network,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: FirestoreSettingsToken, useValue: {} },
    { provide: FUNCTIONS_REGION, useValue: 'us-central1' },
  ],
  bootstrap: 
  [
    AppComponent,
  ]
})
export class AppModule {}
