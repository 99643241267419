// Add your providers here for easy indexing.
export { TranslateProvider } from './translate/translate.service';
export { AuthService } from './auth/auth.service';
export { FirestoreService } from './firestore/firestore.service';
export { ToastService } from './toast/toast.service';
export { LoadingService } from './loading/loading.service';
export { NetworkService } from './network/network.service';
export { StorageService } from './storage/storage.service';
export { NotificationService } from './notification/notification.service';
export { PreloaderService } from './preloading/preloading.service';
export { CartService } from './cart/cart.service';
export { UtilsService } from './util/utils.service';
export { Events } from './events/events.service';
export { OrdersDB } from './firestore/OrdersDB.service';
export { UsersDB } from './firestore/UsersDB.service';
export { CategoriesDB } from './firestore/CategoriesDB.service';
export { ProductsDB } from './firestore/ProductsDB.service';
export { CommercesDB } from './firestore/CommercesDB.service';
