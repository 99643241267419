import { Platform } from '@ionic/angular';
import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { FirestoreService } from '../firestore/firestore.service';
import { TranslateProvider } from '../translate/translate.service';
import { StorageService } from '../storage/storage.service';
import { NotificationService } from '../notification/notification.service';
import { Events } from '../events/events.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../../models';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  //public user: User;
  private fbSubscription: Subscription;
  private fsSubscription: Subscription;
  private count = 0;

  arrayModel = [];
  //model = new User();
  collectionName = 'users';
  collectionField = 'created_at';
  orderByDir: any = 'desc';
  collectionLimit = 1000;
  //observer
  //private modelWatcher: BehaviorSubject<User>;
  modelWatcher = new BehaviorSubject(new User());
  //modelWat = new BehaviorSubject(new User());
  public user: Observable<User>;
  constructor(
    private platform: Platform,
    private afAuth: AngularFireAuth,
    private facebook: Facebook,
    private googlePlus: GooglePlus,
    private firestore: FirestoreService,
    private storage: StorageService,
    public translate: TranslateProvider,
    public notification: NotificationService,
    private router: Router,
    public events: Events,
  ) { }
  getUserAsync(val1): Observable<any> {
    if (this.modelWatcher.value.uid == '') {
      this.getUser(val1).then((res0) => { }).catch((e) => { console.log(e); })
    }
    console.log('getUserAsync(): from ' + val1)
    return this.modelWatcher.asObservable();
  }
  setUser(val1) {
    return new Promise((resolve, reject) => {
      this.modelWatcher.next(val1);
      resolve(true);
    });
  }
  async getUser(val1): Promise<User> {
    this.count += 1;
    console.log('getUser() : ' + this.count + ' from ' + val1)
    return new Promise((resolve, reject) => {
      if (this.modelWatcher.value.uid != '') {
        console.log('auth getUserLocal() from ' + val1);
        console.log('auth from ' + val1+' '+this.modelWatcher.value);
        resolve(this.modelWatcher.value)
      } else {
        if (this.fbSubscription) { this.fbSubscription.unsubscribe(); }
        this.fbSubscription = this.afAuth.authState.subscribe((userFirebase: firebase.User) => {
          if (userFirebase) {
            console.log('auth getUserFirebase() from ' + val1);
            this.notification.init(userFirebase.uid);
            this.firestore.get('users/' + userFirebase.uid).then(ref => {
              if (this.fsSubscription) { this.fsSubscription.unsubscribe(); }
              this.fsSubscription = ref.valueChanges().subscribe((userLocal: User) => {
                if (userFirebase.emailVerified == true && userLocal.emailVerified == false) {
                  userLocal.emailVerified = true;
                  this.updateEmailVerified(userLocal);
                }
                console.log('authsync : getUserFirestore(): ' + this.count + 'from ' + val1);
                this.setUser(userLocal).then((res2) => {
                  resolve(userLocal);
                }), e => { console.log(e); reject(e); };
              }), e => { console.log(e); reject(e); };
            }).catch((e) => { console.log(e); reject(e); });
          } else { reject(); this.logout(); }
        }), e => { console.log(e); reject(e); };
      }
    });
  }
  public updateEmailVerified(user): Promise<any> {
    return new Promise((resolve, reject) => {
      this.firestore.save('users', user).then(res => {
        console.log('updateEmailVerified() true');
        resolve(res);
      }).catch(err => {
        console.log('updateEmailVerified() false');
        reject(err);
      });
    });
  }
  /*async getUser(val1): Promise<User> {
    this.count += 1;
    console.log('getUser() : ' + this.count + ' from ' + val1)
    return new Promise((resolve, reject) => {
      if (this.model.uid != '') {
        console.log('auth getUserLocal() from ' + val1);
        resolve(this.model)
      } else {
        if (this.fbSubscription) { this.fbSubscription.unsubscribe(); }
        this.fbSubscription = this.afAuth.authState.subscribe((userFirebase: firebase.User) => {
          if (userFirebase) {
            console.log('auth getUserFirebase() from ' + val1);
            this.notification.init(userFirebase.uid);
            this.firestore.get('users/' + userFirebase.uid).then(ref => {
              if (this.fsSubscription) { this.fsSubscription.unsubscribe(); }
              this.fsSubscription = ref.valueChanges().subscribe((userLocal: User) => {
                this.model = userLocal;
                console.log('auth getUserFirestore() from ' + val1);
                if (userFirebase.emailVerified == true && userLocal.emailVerified == false) {
                  //this.model.emailVerified = true;
                  //this.updateEmailVerified(this.model);
                }
                resolve(this.model);
              });
            }).catch((e) => {
              reject(e);
            });
          } else {
            reject(false);
          }
        });
      }
    });
  }
  */
  // Change password of the logged in user on Firebase.
  public changePassword(password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.currentUser.updatePassword(password).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  }
  // Reset password of the logged in user on Firebase.
  public resetPassword(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.sendPasswordResetEmail(email).then((res) => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  }
  //Link to verification user account
  public sendEmailVerification(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.authState.subscribe(user => {
        user.sendEmailVerification().then((res) => {
          resolve(res);
        }).catch(err => {
          reject(err);
        });
      });
    });
  }
  // Login to Firebase using email and password combination.
  public loginWithEmail(email: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signInWithEmailAndPassword(email, password).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  }
  // Register an account on Firebase with email and password combination.
  public registerWithEmail(email: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.createUserWithEmailAndPassword(email, password).then(res => {
        console.log('1 - createUserWithEmailAndPassword res: ', res);
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  }
  // Log the user out from Firebase.
  public logout(): Promise<any> {
    console.log('1-logout()');
    return new Promise((resolve, reject) => {
      console.log('2-logout()');
      this.afAuth.auth.signOut().then(() => {
        console.log('3-logout()');
        this.events.publish('main:chat', { 'chat': false });
        this.events.publish('main:alert', { 'alert': false });
        this.events.publish('main:account', { 'account': false });
        this.events.publish('user:session', false);

        localStorage.setItem('user', null);
        localStorage.setItem('orders', null);
        localStorage.setItem('lastBuy', null);
        this.storage.remove('uid');
        this.facebook.logout();
        this.googlePlus.logout();
        this.router.navigate(['/login']);
        //resolve();
      }).catch(() => {
        reject();
      });
    });
  }
  public delete(): Promise<any> {
    return new Promise((resolve, reject) => {
      var user = this.afAuth.auth.currentUser;
      user.delete().then(() => {
        resolve(true);
      }).catch(() => {
        reject(false);
      });
    });
  }

  // Login on Firebase using Facebook.
  public authWithFacebook(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.platform.is('cordova')) {
        this.facebook.login(['email']).then((res: FacebookLoginResponse) => {
          const credential = firebase.auth.FacebookAuthProvider.credential(res.authResponse.accessToken);
          this.afAuth.auth.signInWithCredential(credential).then(response => {
            // console.log(response.user);
            // resolve(response.user);
            console.log(response);
            resolve(response);
          }).catch(err => {
            console.log(err);
            reject(this.translate.get(err.code));
          });
        }).catch(err => {
          console.log(err);
          // User cancelled, don't show any error.
          reject();
        });
      } else {
        const fbprovider = new firebase.auth.FacebookAuthProvider();

        this.afAuth.auth.signInWithPopup(fbprovider).then(res => {
          resolve(res);
        }).catch((e) => {
          reject(e);
        });
      }
    });
  }

  // Login on Firebase using Google.
  public authWithGoogle(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.platform.is('cordova')) {
        this.googlePlus.login({
          'webClientId': environment.googleWebClientId,
          'offline': true // set FALSE or remove this line before you build and release a prod version.
        }).then(res => {
          const credential = firebase.auth.GoogleAuthProvider.credential(res.idToken, res.accessToken);
          this.afAuth.auth.signInWithCredential(credential).then(ress => {
            resolve(ress);
          }).catch(err => {
            reject(err.code);
          });
        }).catch(err => {
          //  User cancelled, don't show any error.
          reject();
        });
      } else {
        const gprovider = new firebase.auth.GoogleAuthProvider();

        this.afAuth.auth.signInWithPopup(gprovider).then(res => {
          resolve(res);
        }).catch((e) => {
          reject(e);
        });
      }
    });
  }




}
