import { Component, OnInit } from '@angular/core';
import { TranslateProvider, AuthService, Events, OrdersDB, CommercesDB } from './../../services';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  user = new User();
  is_logged = false;
  sub1: Subscription;
  countAlert = 0;
  constructor(
    public events: Events,
    public router: Router,
    public auth: AuthService,
    public commercesDB: CommercesDB,
    public ordersDB: OrdersDB,
  ) {
    this.loadAlerts();
    this.events.subscribe('alert:count', (data) => {

    });
  }
  ngOnInit() {
  }
  setCountAlert(val1) {
    if(this.countAlert != val1){
      this.countAlert = val1;
    }
  }
  alert() {
    console.log('alert publish')
    this.events.publish('main:alert', { 'openAlert': true });
  }
  account() {
    console.log('account publish')
    this.events.publish('main:account', { 'openAccount': true });
  }
  chat() {
    console.log('chat publish')
    this.events.publish('main:chat', { 'chat': true });
  }
  goToHome() {
    let audio = new Audio();
    audio.src = "./../../assets/audio/hangouts_message.ogg";
    audio.load();
    audio.play();
    this.router.navigate([`home`]);
  }
  loadAlerts() {
    if (this.sub1) { this.sub1.unsubscribe(); }
    this.auth.getUser('header').then(res1 => {
      console.log("IMAGEEEN");
      this.user = res1;
      console.log(res1.image);
      this.ordersDB.getAlertsAsync(res1.uid).subscribe(res2 => {
        this.setCountAlert(res2.length)
        this.events.publish('main:alert', { from: 'header', loadAlert: true, alerts: res2 });
      }, (e) => { console.log(e) });
    }, (e) => { console.log(e) });
  }
}
