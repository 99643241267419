import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FCM } from '@ionic-native/fcm/ngx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Headers, RequestOptions } from '@angular/http';

import { FirestoreService } from '../firestore/firestore.service';
import { ToastService } from '../toast/toast.service';
import { TranslateProvider } from '../translate/translate.service';

import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';

// import { User } from '../../models';
import { environment } from '../../../environments/environment';
import { AngularFireFunctions } from '@angular/fire/functions';



@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  token;

  constructor(
    private platform: Platform,
    private fcm: FCM,
    private afm: AngularFireMessaging,
    private firestore: FirestoreService,
    private toast: ToastService,
    private translate: TranslateProvider,
    private http: HttpClient,
    private functions: AngularFireFunctions,

  ) { }

  // Called after user is logged in to set the pushToken on Firestore.
  public init(uid): void {
    if (!!this.token == false) {
      if (this.platform.is('cordova')) {
        this.fcm.getToken().then((token: string) => {
          this.token = token;
          console.log('notification: init(): getToken: ' + token);
          this.firestore.setPushToken(uid, token);
          this.fcm.onTokenRefresh().subscribe((token: string) => {
            this.token = token;
            console.log('notification: init(): onTokenRefresh: ' + token);
            this.firestore.setPushToken(uid, token);
          });
          this.fcm.onNotification().subscribe(data => {
            console.log('notification: init(): onNotification: ' + JSON.stringify(data));
            if (data.wasTapped) {
              //this.toast.showToast(data.title + ' ' + data.body);
            } else {
              //this.toast.showToast(data.title + ' ' + data.body);
            }
          });
        }).catch(err => {
          console.log(err);
          console.log('Error Saving Token: ', JSON.stringify(err));
        });
      } else {
        this.afm.requestToken.subscribe(
          (token) => {
            this.token = token;
            console.log('notification: init(): requestToken: ' + this.token);
            this.firestore.setPushToken(uid, token);
            this.afm.messages.subscribe((data: any) => {
              console.log('notification: init(): message: ' + JSON.stringify(data));
              if (data.wasTapped) {
                //this.toast.showToast(data.title + ' ' + data.body);
              } else {
                //this.toast.showToast(data.title + ' ' + data.body);
              }
            });
          },
          (error) => { console.error(error); }
        );
      }
    }
  }

  public destroy(uid): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log('notification: destroy(): message: ');
      if (this.platform.is('cordova')) {
        this.firestore.removePushToken(uid);
        this.token = null;
        this.fcm.onTokenRefresh().subscribe().unsubscribe();
        resolve(true);
      } else {
        this.firestore.removePushToken(uid);
        this.token = null;
        this.afm.requestToken.subscribe().unsubscribe();
        resolve(true);
      }
    });
  }
  public getPushToken(uid) {
    return new Promise((resolve, reject) => {
      this.firestore.getOne('user_push_tokens', uid).subscribe((data) => {
        resolve(data);
      }), (function (error) {
        console.log(error);
        resolve(null);
      });
    });
  }
  public setTopic(topic) {
    return this.fcm.subscribeToTopic(topic);
  }

  public getPushTokenFromUsr(uid) {
    return new Promise((resolve, reject) => {
      this.firestore.getOne('users', uid).subscribe((data) => {
        console.log(data);
        resolve(data);
      }), (function (error) {
        console.log(error);
        reject(false);
      });
    });
  }
  
  public removeTopic(topic) {
    return this.fcm.unsubscribeFromTopic(topic);
  }

  // Send a push notification given the pushToken, and the message.
  // Change the title, body, and data according to your requirements.
  public sendPush(token: string, title: string, body: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const postParams = {
        'notification': {
          'title': title,
          'body': body,
          'badge': 1,
          'sound': 'default',
          'click_action': 'FCM_PLUGIN_ACTIVITY',
          'icon': 'ic_launcher'
        },
        'data': {
          'title': title,
          'body': body,
        },
        'to': token,
        'priority': 'high',
        'restricted_package_name': ''
      };

      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'key=' + environment.gcmKey
      });

      const options = { headers: headers };

      this.http.post('https://fcm.googleapis.com/fcm/send', postParams, options).subscribe(response => {
        resolve(response);
      }, err => {
        reject(err);
      });
    });
  }
  notify(nameFunction, paramsObject) {
    if (nameFunction != '' && paramsObject != null) {
      const callable = this.functions.httpsCallable(nameFunction);
      const obs = callable(paramsObject);
    }
  }

  sendAdminZone(nameFunction, paramsObject){
    if (nameFunction != '' && paramsObject != null) {
      const callable = this.functions.httpsCallable(nameFunction);
      const obs = callable(paramsObject);
    }
  }
}
