import { Injectable } from '@angular/core';
import { Events } from './../../services/events/events.service';
import { UtilsService } from './../../services/util/utils.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  promotions = [];
  categories = [];
  products = [];
  combos = [];
  //public countBadge: number = 0;

  constructor(
    private util: UtilsService,
    public events: Events,
  ) {
  }

  loadPromotions()
  {
    this.promotions =
    [
    {'id':1,'title':'promotion 1','img':'assets/img/i1.png','description':''},
    {'id':2,'title':'promotion 2','img':'assets/img/i1.png','description':''},
    {'id':3,'title':'promotion 3','img':'assets/img/i1.png','description':''},
    ];
  }

  loadCategories()
  {
    this.categories =
    [
    {'CategoryID':1,'title':'starter','img':'assets/img/i1.png','description':''},
    {'CategoryID':2,'title':'seafood','img':'assets/img/i1.png','description':''},
    {'CategoryID':3,'title':'steak','img':'assets/img/i1.png','description':''},
    {'CategoryID':4,'title':'drinks','img':'assets/img/i1.png','description':''},
    {'CategoryID':5,'title':'salads','img':'assets/img/i1.png','description':''},
    {'CategoryID':6,'title':'desserts','img':'assets/img/i1.png','description':''},
    ];
    localStorage.setItem('categories', JSON.stringify(this.categories));

  }

  loadProducts()
  {
    this.products =
    [
    {'ProductID':1,'CategoryID':1,'title':'Boneless Wigns','img':'assets/img/i1.png','description':'our signature hand-breaded',price:'1000'},
    {'ProductID':2,'CategoryID':2,'title':'Mozarella Moon','img':'assets/img/i1.png','description':'fires with crispy, crunchy',price:'1000'},
    {'ProductID':3,'CategoryID':3,'title':'Seared steak','img':'assets/img/i1.png','description':'thin fleatbread is glazed',price:'1000'},
    {'ProductID':4,'CategoryID':1,'title':'Chicken dip','img':'assets/img/i1.png','description':'thin fleatbread is glazed',price:'1000'},
    {'ProductID':5,'CategoryID':2,'title':'product 5','img':'assets/img/i1.png','description':'thin fleatbread is glazed',price:'1000'},
    {'ProductID':6,'CategoryID':3,'title':'product 4','img':'assets/img/i1.png','description':'thin fleatbread is glazed',price:'1000'},
    {'ProductID':7,'CategoryID':1,'title':'product 6','img':'assets/img/i1.png','description':'thin fleatbread is glazed',price:'1000'},
    {'ProductID':8,'CategoryID':2,'title':'product 7','img':'assets/img/i1.png','description':'thin fleatbread is glazed',price:'1000'},
    {'ProductID':9,'CategoryID':3,'title':'product 8','img':'assets/img/i1.png','description':'thin fleatbread is glazed',price:'1000'},
    {'ProductID':10,'CategoryID':1,'title':'product 9','img':'assets/img/i1.png','description':'thin fleatbread is glazed',price:'1000'},
    {'ProductID':11,'CategoryID':2,'title':'product 10','img':'assets/img/i1.png','description':'thin fleatbread is glazed',price:'1000'},
    {'ProductID':12,'CategoryID':3,'title':'product 11','img':'assets/img/i1.png','description':'thin fleatbread is glazed',price:'1000'},
    {'ProductID':13,'CategoryID':1,'title':'product 12','img':'assets/img/i1.png','description':'thin fleatbread is glazed',price:'1000'},
    ];
    localStorage.setItem('products', JSON.stringify(this.products));

  }

  updateCart(action, product, quantity, productId) {
    let cart_items = localStorage.getItem('shoping_cart') ? JSON.parse(localStorage.getItem('shoping_cart')) : [];
    cart_items = cart_items.filter(product => product.uid !== productId);
    if (action == 'add') {
      let subtotal = (parseInt(quantity) * parseFloat(eval(product.price)));
      subtotal = Number(subtotal.toFixed(2));
      let sum = 0;
      cart_items.push({
        'uid': product.uid,
        'name': product.name,
        'quantity': quantity,
        'price': product.price,
        'image': product.image,
        'subtotal': subtotal,
      });
    }
    
    cart_items = this.calculateCartSum(cart_items);
    localStorage.setItem('shoping_cart', JSON.stringify(cart_items));
    return this.saveCart();
  }
  saveCart() {
    let cart_info = localStorage.getItem('shoping_cart_info') ? JSON.parse(localStorage.getItem('shoping_cart_info')) : {};
    let cart_items = localStorage.getItem('shoping_cart') ? JSON.parse(localStorage.getItem('shoping_cart')) : [];
    let count_items = cart_items.length;
    //this.countBadge = count_items;
    cart_info = { items: cart_items, subtotal: this.calculateCart(cart_items), count_items: count_items };
    localStorage.setItem('shoping_cart_info', JSON.stringify(cart_info));
    this.events.publish('updateCart', count_items);
    //this.events.publish('profileUpdated', count_items);
    return count_items;
  }
  removeCart() {
    let cart_items = [];
    let cart_info = { items: cart_items, subtotal: this.calculateCart(cart_items), count_items: cart_items.length };
    localStorage.setItem('shoping_cart', JSON.stringify(cart_items));
    localStorage.setItem('shoping_cart_info', JSON.stringify(cart_info));
    this.events.publish('updateCart', 0);
  }
  calculateCart(products) {
    let subtotal = 0;
    products.forEach(element => { subtotal += eval(element.subtotal); });
    let subtotal_ = subtotal.toFixed(2)
    return subtotal_;
  }
  calculateCartSum(products) {
    let subtotal = 0;
    products.forEach(element => { subtotal += eval(element.subtotal); element.sum = subtotal; });
    return products;
  }
  calculateDate() {
    let res = [];

    let hour0 = moment().format('HA');
    let day0 = moment().format('e');
    let month0 = moment().format('MMMM');
    let thisMoment0 = '', thisMoment1 = '', thisMoment2 = '';
    var currentHour = parseFloat(hour0);

    thisMoment0 = this.calculateMoment(currentHour);
    if (thisMoment0 == "madrugada") {
      if (day0 == '5') {
        var r1 = this.getNextMoment(0);
        var r2 = this.getNextMoment(2);
        thisMoment1 = 'Entre 8AM - 12PM ' + r1.dia + ' ' + r1.num + ' de ' + r1.mes;
        thisMoment2 = 'Entre 8AM - 12PM ' + r2.dia + ' ' + r2.num + ' de ' + r2.mes;
      } else if (day0 == '6') {
        var r1 = this.getNextMoment(1);
        thisMoment1 = 'Entre 8AM - 12PM ' + r1.dia + ' ' + r1.num + ' de ' + r1.mes;
        thisMoment2 = 'Entre 1PM - 5PM ' + r1.dia + ' ' + r1.num + ' de ' + r1.mes;
      } else {6
        var r1 = this.getNextMoment(0);
        thisMoment1 = 'Entre 8AM - 12PM ' + r1.dia + ' ' + r1.num + ' de ' + r1.mes;
        thisMoment2 = 'Entre 1PM - 5PM ' + r1.dia + ' ' + r1.num + ' de ' + r1.mes;
      }
    } else if (thisMoment0 == "mañana") {
      if (day0 == '5') {
        var r1 = this.getNextMoment(2);
        thisMoment1 = 'Entre 8AM - 12PM ' + r1.dia + ' ' + r1.num + ' de ' + r1.mes;
        thisMoment2 = 'Entre 1PM - 5PM ' + r1.dia + ' ' + r1.num + ' de ' + r1.mes;
      } else if (day0 == '6') {
        var r1 = this.getNextMoment(1);
        thisMoment1 = 'Entre 8AM - 12PM ' + r1.dia + ' ' + r1.num + ' de ' + r1.mes;
        thisMoment2 = 'Entre 1PM - 5PM ' + r1.dia + ' ' + r1.num + ' de ' + r1.mes;
      } else {
        var r1 = this.getNextMoment(0);
        var r2 = this.getNextMoment(1);
        thisMoment1 = 'Entre 1PM - 5PM ' + r1.dia + ' ' + r1.num + ' de ' + r1.mes;
        thisMoment2 = 'Entre 8AM - 12PM ' + r2.dia + ' ' + r2.num + ' de ' + r2.mes;
      }
    } else if (thisMoment0 == "tarde" || thisMoment0 == "noche") {
      if (day0 == '4') {
        var r1 = this.getNextMoment(1);
        var r2 = this.getNextMoment(3);
        thisMoment1 = 'Entre 8AM - 12PM  ' + r1.dia + ' ' + r1.num + ' de ' + r1.mes;
        thisMoment2 = 'Entre 8AM - 12PM ' + r2.dia + ' ' + r2.num + ' de ' + r2.mes;
      } else if (day0 == '5') {
        var r1 = this.getNextMoment(2);
        thisMoment1 = 'Entre 8AM - 12PM ' + r1.dia + ' ' + r1.num + ' de ' + r1.mes;
        thisMoment2 = 'Entre 1PM - 5PM ' + r1.dia + ' ' + r1.num + ' de ' + r1.mes;
      } else {
        var r1 = this.getNextMoment(1);
        thisMoment1 = 'Entre 8AM - 12PM ' + r1.dia + ' ' + r1.num + ' de ' + r1.mes;
        thisMoment2 = 'Entre 1PM - 5PM ' + r1.dia + ' ' + r1.num + ' de ' + r1.mes;
      }
    }
    res['fecha0'] = '(' + currentHour + ') (' + thisMoment0 + ') ' + hour0 + ' ' + day0 + ' de ' + month0;
    res['fecha1'] = thisMoment1;
    res['fecha2'] = thisMoment2;

    return res;
  }
  calculateMoment(currentHour) {
    return currentHour >= 0 && currentHour < 6 ? 'madrugada' : currentHour >= 6 && currentHour < 12 ? 'mañana' : currentHour >= 12 && currentHour < 18 ? 'tarde' : 'noche';
  }
  getNextMoment(days) {
    return {
      dia: moment().add(days, 'days').format('dddd'),
      num: moment().add(days, 'days').format('D'),
      mes: moment().add(days, 'days').format('MMMM')
    };
  }
  public findProductsById(val1)
  {
    let res = {};
    let products = localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : {};
    products = products.filter(product => product.ProductID == val1);
    if(products.length>0){
      res = products[0];
    }
    return res;
  }
  updateBagde() {
    let res = 0
    return res; 
  }
}
