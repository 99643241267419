import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Commerce, } from '../../models';
import { FirestoreService } from './firestore.service';
import { AuthService } from './../auth/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommercesDB {
  //observables
  //observables
  model = new BehaviorSubject(new Commerce);
  arrayModel = new BehaviorSubject([]);
  arrayModelAll = new BehaviorSubject([]);

  private arrayAlerts: BehaviorSubject<[]>;
  collectionName = 'commerces';
  collectionField = 'created_at';
  orderByDir: any = 'desc';
  collectionLimit = 1000;
  constructor(
    private af: AngularFirestore,
    private fs: FirestoreService,
    private as: AuthService,
  ) { }
  getOne(uid) {
    let findByUID = this.arrayModelAll.value.filter(item => item['uid'] == uid);
    if (findByUID.length > 0) {
      this.setOne(findByUID[0]).then((res1) => { }), e => { console.log(e); };
    } else {
      this.fs.getOne(this.collectionName, uid).subscribe(res1 => {
        this.setOne(res1).then((res2) => { }), e => { console.log(e); };
      }), e => { console.log(e); };
    }
    return this.model.asObservable();
  }
  setOne(val1) {
    return new Promise((resolve, reject) => {
      this.model.next(val1);
      resolve(true);
    });
  }
  getWhere(key1, val1) {
    if ((this.arrayModel.value.length == 0)) {
      this.fs.getWhere(this.collectionName, key1, val1).subscribe(res1 => {
        this.shortByCreatedAt(res1).then((res2) => {
          this.setWhere(res1).then((res3) => {
            return this.arrayModel.asObservable();
          }), e => { console.log(e); };
        }), e => { console.log(e); };
      }), e => { console.log(e); };
    }
    return this.arrayModel.asObservable();
  }
  setWhere(val1) {
    return new Promise((resolve, reject) => {
      this.arrayModel.next(val1);
      resolve(true);
    });
  }
  getAll(uid) {
    if ((this.arrayModelAll.value.length == 0 && uid != null && uid != undefined)) {
      console.log(uid)
      this.fs.getWhere(this.collectionName, 'manager_user_uid', uid).subscribe(res1 => {
        //console.log('commerces array '+ JSON.stringify((res1)));
        this.shortByCreatedAt(res1).then((res2) => {
          this.setAll(res1).then((res3) => {
          }), e => { console.log(e); };
        }), e => { console.log(e); };
      }), e => { console.log(e); };
    } 
    return this.arrayModelAll.asObservable();
  }
  setAll(val1) {
    return new Promise((resolve, reject) => {
      this.arrayModelAll.next(val1);
      resolve(true);
    });
  }
  getAlerts(uid) {
    if ((this.arrayAlerts.value.length == 0)) {
      this.fs.getWhere2(this.collectionName, 'manager_uid', uid, 'is_notified_commerce', false).subscribe(res1 => {
        this.setAlerts(res1).then((res2) => {
          return this.arrayAlerts.asObservable();
        }), e => { console.log(e); };
      }), e => { console.log(e); };
    } else {
      return this.arrayAlerts.asObservable();
    }
  }
  setAlerts(val1) {
    return new Promise((resolve, reject) => {
      this.arrayAlerts.next(val1);
      resolve(true);
    });
  }
  shortByCreatedAt(val1) {
    return new Promise((resolve, reject) => {
      val1.sort(function (a, b) {
        if (a.created_at > b.created_at) { return -1; }
        if (a.created_at < b.created_at) { return 1; }
        return 0;
      });
      resolve(val1);
    });
  }
}
