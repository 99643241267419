import { Component, OnInit } from '@angular/core';
import { TranslateProvider, AuthService, Events, OrdersDB, CommercesDB } from './../../services';
import { Platform, NavController, MenuController } from '@ionic/angular';

@Component({
  selector: 'app-side-alert',
  templateUrl: './side-alert.component.html',
  styleUrls: ['./side-alert.component.scss'],
})
export class SideAlertComponent implements OnInit {
  email = '';
  photoURL = '';
  is_super = false;
  tableData = []
  constructor(
    private auth: AuthService,
    private menu: MenuController,
    public navCtrl: NavController,
    public events: Events,
    public commercesDB: CommercesDB,
    public ordersDB: OrdersDB,
  ) {
    //this.loadAlerts();
    this.events.subscribe('main:alert', (data) => {
      if (data['openAlert']) { this.openAlert(); }
      if (data['loadAlert']) { this.loadAlerts(data['alerts']); }
    });
  }
  ngOnInit() { }
  loadAlerts(alerts) {
    this.tableData = alerts;
  }
  alertOn() {
    this.menu.enable(true, 'menuAlert');
  }
  alertOff() {
    this.menu.enable(false, 'menuAlert');
  }
  openAlert() {
    this.menu.open('menuAlert');
  }
  closeAlert() {
    this.menu.close('menuAlert');
  }
  asd() {

  }
}
