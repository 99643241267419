import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { User } from '../../models';
import { FirestoreService } from './firestore.service';
import { AuthService } from './../auth/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UsersDB {
  modelAffiliated = new BehaviorSubject(new User);
  modelYet = new BehaviorSubject(new User);
  modelClient = new BehaviorSubject(new User);
  arrayAffiliates = new BehaviorSubject([]);
  arrayYellowGoAffiliates = new BehaviorSubject([]);
  arrayOtherCommerce = new BehaviorSubject([]);
  arrayYets = new BehaviorSubject([]);
  arrayClients = new BehaviorSubject([]);
  collectionName = 'users';
  collectionField = 'created_at';
  orderByDir: any = 'desc';
  collectionLimit = 1000;
  constructor(
    private af: AngularFirestore,
    private fs: FirestoreService,
    private as: AuthService,
  ) { }
  getOneAffiliated(uid){
    let findByUID = this.arrayAffiliates.value.filter(item => item['uid'] == uid);
      if (findByUID.length > 0) {
        this.setOneAffiliated(findByUID[0]).then((res1) => {
        }), e => { console.log(e); };
      } else {
        this.fs.getOne(this.collectionName, uid).subscribe(res1 => {
          this.setOneAffiliated(res1).then((res2) => {
          }), e => { console.log(e); };
        }), e => { console.log(e); };
      }
      return this.modelAffiliated.asObservable();
  }
  setOneAffiliated(val1) {
    return new Promise((resolve, reject) => {
      this.modelAffiliated.next(val1);
      resolve(true);
    });
  }

  getAffiliates(uid): Observable<any> {
    if ((this.arrayAffiliates.value.length == 0)) {
      this.fs.getWhere(this.collectionName, 'is_admin', true).subscribe(res1 => {
        this.setAffiliates(res1).then((res2) => {
        }), e => { console.log(e); };
      }), e => { console.log(e); };
    }
    return this.arrayAffiliates.asObservable();
  }

  getYellowGoAffiliates(): Observable<any>{
    if ((this.arrayYellowGoAffiliates.value.length == 0)) {
      this.fs.getWhere('commerces','isYellowGoAffiliate',true).subscribe(res1 => {
        this.setYellowGoAffiliates(res1).then((res2) => {
        }), e => { console.log(e); };
      }), e => { console.log(e); };
    }
    return this.arrayYellowGoAffiliates.asObservable();
  }

  getOtherCommerce(zone): Observable<any>{
    if ((this.arrayOtherCommerce.value.length == 0)) {
      this.fs.getWhere('commerces','zone',zone).subscribe(res1 => {
        this.setOtherCommerce(res1).then((res2) => {
        }), e => { console.log(e); };
      }), e => { console.log(e); };
    }
    return this.arrayOtherCommerce.asObservable();
  }

  setYellowGoAffiliates(val1) {
    return new Promise((resolve, reject) => {
      this.arrayYellowGoAffiliates.next(val1);
      resolve(true);
    });
  }

  setOtherCommerce(val1) {
    return new Promise((resolve, reject) => {
      this.arrayOtherCommerce.next(val1);
      resolve(true);
    });
  }

  setAffiliates(val1) {
    return new Promise((resolve, reject) => {
      this.arrayAffiliates.next(val1);
      resolve(true);
    });
  }
  getOneYet(uid){
    let findByUID = this.arrayYets.value.filter(item => item['uid'] == uid);
      if (findByUID.length > 0) {
        this.setOneYet(findByUID[0]).then((res1) => {
        }), e => { console.log(e); };
      } else {
        this.fs.getOne(this.collectionName, uid).subscribe(res1 => {
          this.setOneYet(res1).then((res2) => {
          }), e => { console.log(e); };
        }), e => { console.log(e); };
      }
      return this.modelYet.asObservable();
  }
  setOneYet(val1) {
    return new Promise((resolve, reject) => {
      this.modelYet.next(val1);
      resolve(true);
    });
  }
  getYets(uid): Observable<any> {
    if ((this.arrayYets.value.length == 0)) {
      this.fs.getWhere(this.collectionName, 'is_driver', true).subscribe(res1 => {
        this.setYets(res1).then((res2) => {
        }), e => { console.log(e); };
      }), e => { console.log(e); };
    }
    return this.arrayYets.asObservable();
  }
  setYets(val1) {
    return new Promise((resolve, reject) => {
      this.arrayYets.next(val1);
      resolve(true);
    });
  }
  getOneClient(uid): Observable<any> {
    let findByUID = this.arrayClients.value.filter(item => item['uid'] == uid);
    if (findByUID.length > 0) {
      this.setOneClient(findByUID[0]).then((res1) => {
      }), e => { console.log(e); };
    } else {
      this.fs.getOne(this.collectionName, uid).subscribe(res1 => {
        this.setOneClient(res1).then((res2) => {
        }), e => { console.log(e); };
      }), e => { console.log(e); };
    }
    return this.modelClient.asObservable();
  }
  setOneClient(val1) {
    return new Promise((resolve, reject) => {
      this.modelClient.next(val1);
      resolve(true);
    });
  }
  getClients(uid): Observable<any> {
    if ((this.arrayClients.value.length == 0)) {
      this.fs.getWhere(this.collectionName, 'is_client', true).subscribe(res1 => {
        this.setClients(res1).then((res2) => {
        }), e => { console.log(e); };
      }), e => { console.log(e); };
    }
    return this.arrayClients.asObservable();
  }
  setClients(val1) {
    return new Promise((resolve, reject) => {
      this.arrayClients.next(val1);
      resolve(true);
    });
  }
}
