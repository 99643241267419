import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController,ToastController } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { TranslateProvider, AuthService, Events, OrdersDB, CommercesDB } from './../../services';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor(
    public toastController: ToastController,
    public loadingController: LoadingController,
    private router: Router,
  ) { }
  public goToPage(page=null,param={})
  {
    if(!!page)
    {
      this.router.navigate([`/${page}`, param]);
    }
  }
  public uuidv4() {
    return 'xxxx-xxxx-xxxx-xxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  public newId(): any {
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let autoId = '';
    for (let i = 0; i < 20; i++) {
      autoId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    if(autoId.length === 20)
    {
      return this.newId();
    }else{
      return autoId;
    }
  }
  public validate(value, type = 'any') {
    let res = { "done": false, message: `${type} invalid` };
    if(value != "")
    {
      let REGEXP = /./g;
      if(type == 'email' )
      {
        REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      }
      else if(type == 'password')
      {
        //REGEXP = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm;
      }
      if(REGEXP.test(value))
      {
        res.done = true;
      }
      else
      {
        res.message = `${type} tiene un formato invalido`;
      }
    }
    else
    {
      res.message = `${type} esta vacio`;
    }
    return res;
  }
  public isObjectEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }
  async showLoading(message: string) {
    const loading = await this.loadingController.create({
      spinner: 'crescent',
      duration: environment.loading.duration,
      message: message
    });
    return await loading.present();
  }
  async dismissLoading() {
    // this.isLoading = false;
    return await this.loadingController.dismiss().then(() => console.log('dismissed'));
  }
  async showToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      showCloseButton: true,
      duration: environment.toast.duration,
      closeButtonText: 'Done'
    });
    return toast.present();
  }
}
